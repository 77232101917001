import { render, staticRenderFns } from "./StudentID.vue?vue&type=template&id=4ca6d7b8&"
import script from "./StudentID.vue?vue&type=script&lang=ts&"
export * from "./StudentID.vue?vue&type=script&lang=ts&"
import style0 from "./StudentID.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VDatePicker,VMenu,VSelect,VSkeletonLoader,VSpacer,VTextField})
